import React from "react";

function CareerPage() {
  React.useEffect(() => {
    window.location.replace("https://career.hyperconnect.com/")
  }, []);

  return (
    <>
     redirecting...
     <a
      href="https://career.hyperconnect.com/"
      rel="noreferrer"
      >
        https://career.hyperconnect.com/
      </a>
    </>
  );
};

export default CareerPage;
